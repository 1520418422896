import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import ActionButton from 'components/ApplicantDrawerDetailsList/components/Field/ActionButtons/ActionButton';
import {
  addDefaultErrorMessageAction,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import useUserPermissions from 'hooks/useUserPermissions';
import messages from 'shared/global/messages';

import { DetailType, DetailValueType } from '../../../props';
import { ActionTypeEnum } from './constants';

const ActionButtonsContainer = ({
  detail,
  isFieldEmpty,
  onClickCopy = () => {},
  onClickClear = () => {},
  onClickEdit = () => {},
  value,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    manageApplicantsPermission: canManageApplicants,
    manageSecureDataPermission: canManageSecureData,
  } = useUserPermissions();

  const copySuccess = formatMessage(messages.copySuccess);
  const tooltipCopy = formatMessage(messages.copy);
  const tooltipDelete = formatMessage(messages.clear);
  const tooltipEdit = formatMessage(messages.edit);

  const handleClickCopy = event => {
    event.stopPropagation();

    let text = value;
    if (detail.type === 'address') {
      const filteredAddressParts = [
        value.street_name,
        value.address_2,
        value.city,
        value.state,
        value.zipcode,
        value.country,
      ].filter(
        addressValue => typeof addressValue === 'string' && addressValue,
      );

      text =
        filteredAddressParts.length > 0
          ? filteredAddressParts.reduce((a, b) => `${a}, ${b}`)
          : '';
    }

    const handleSuccess = () =>
      dispatch(addMessageAction(copySuccess, 'success'));

    const handleError = () => dispatch(addDefaultErrorMessageAction());

    window.navigator.clipboard.writeText(text).then(handleSuccess, handleError);
    onClickCopy();
  };

  const handleClickClear = event => {
    event.stopPropagation();
    onClickClear();
  };

  const handleClickEdit = event => {
    event.stopPropagation();
    onClickEdit();
  };

  return (
    <>
      {canManageApplicants && canManageSecureData && (
        <ActionButton
          actionType={ActionTypeEnum.EDIT}
          onClick={handleClickEdit}
          tooltipTitle={tooltipEdit}
        />
      )}
      {!isFieldEmpty && (
        <ActionButton
          actionType={ActionTypeEnum.COPY}
          onClick={handleClickCopy}
          tooltipTitle={tooltipCopy}
        />
      )}
      {canManageApplicants && canManageSecureData && (
        <ActionButton
          actionType={ActionTypeEnum.DELETE}
          onClick={handleClickClear}
          tooltipTitle={tooltipDelete}
        />
      )}
    </>
  );
};

ActionButtonsContainer.propTypes = {
  detail: DetailType.isRequired,
  isFieldEmpty: PropTypes.bool.isRequired,
  onClickCopy: PropTypes.func,
  onClickClear: PropTypes.func,
  onClickEdit: PropTypes.func,
  value: DetailValueType,
};

export default ActionButtonsContainer;
